<template>
  <div>
    <div class="mt-6 flex justify-between">
      <div class="font-bold text-darkblue">
        <h4 class="text-darkblue capitalize">{{ mode }}</h4>
      </div>
      <div>
        <button
          @click="TableButton"
          class="text-white font-bold md:text-xs  bg-darkblue md:pl-3 pl-2 pr-2 md:pr-3 pt-2 pb-2 rounded-lg"
        >
          <img
            class="w-5 h-auto mr-2 hidden md:inline align-middle"
            :src="icons.pen"
          />
          <span class="capitalize"> Add New {{ mode }}</span>
        </button>
      </div>
    </div>
    <div
      class="flex justify-between md:justify-between md:flex-row flex-col-reverse  mt-6"
    >
      <div>
        <form
          @submit.prevent="SearchTable"
          class="h-full flex flex-col  md:flex-row-reverse  "
          action=""
        >
          <div>
            <input
              required
              v-model="searchQuery"
              title="Please input a search phrase"
              class="w-72  md:text-xs  inline h-11 md:h-9 pl-2 pr-2 focus:ring-2 focus:ring-green-600  rounded-l-lg pt-1 pb-1"
              placeholder="    Search for .."
            /><button
              class="bg-darkblue pt-1 pb-1 mb-3 text-white hover:bg-blue-600 focus:text-darkblue focus:bg-lightblue pl-2 pr-2  h-11 md:h-9"
              type="submit"
            >
              <i class="fas fa-search "></i>
            </button>
          </div>
          <div class="md:mr-2 w-2/4 md:w-auto ">
            <t-select
              fixedClasses="inline md:text-xs h-11 md:h-9 rounded-l-lg cursor-pointer"
              placeholder="Filter"
              v-model="searchFilter"
              :options="searchFilterOptions"
              :required="true"
            ></t-select>
          </div>
        </form>
      </div>
      <div class="mb-3 md:mb-0">
        <button
          @click="RefreshTable"
          class="pl-3 md:text-xs bg-white text-darkblue pr-3 border-2 border-darkblue hover:border-blue-700 hover:bg-blue-700 hover:text-white pt-1 pb-1 rounded-lg"
          type="button"
        >
          Refresh Table
        </button>
      </div>
    </div>
    <div>
      <Table
        @clicked-table="NavigateToSingleStock($event)"
        @show-alert="ShowAlert($event)"
        :table_data="table_data"
        :FormatTableData="FormatTableData"
        :search_data="searchData"
        :mode="mode"
        :TableButton="TableButton"
        :table_header="table_header"
      />
    </div>
  </div>
</template>

<script>
  import Table from "../../components/Table.vue";
  import { emitEventMixin } from "@/assets/helpers/emitEventMixin";
  import { mapMutations } from "vuex";

  export default {
    name: "Stock",
    props: {},
    mixins: [emitEventMixin],

    components: {
      Table,
    },
    data() {
      return {
        user_year_query: 2020,
        chart_loading: true,
        hide_add_new_button: true,
        searchFilterOptions: [{ value: "itemName", text: "Stock Name" }],

        mode: "stock",
        searchQuery: null,
        searchFilter: null,
        searchData: {
          model: "stock",
          limit: 5,
          filter: null,
          searchQuery: null,
        },
        card_data: [
          { label: "Total Stock", value: "20" },
          { label: "Low Stock", value: "10" },
          { label: "Exhausted Stock", value: "20" },
        ],
        table_header: [
          {
            value: "itemName",
            text: "Stock Name",
            className: "fortunate-table",
          },
          {
            value: "unit",
            text: "Unit",
            className: "fortunate-table",
          },
          {
            value: "unitSellingPrice",
            text: "Unit Selling Price(₦)",
            className: "fortunate-table",
          },
          {
            value: "quantityRemaining",
            text: "Quantity Remaining",
            className: "fortunate-table",
          },
          {
            value: "createdBy",
            text: "Created By ",
            className: "fortunate-table",
          },
          {
            value: "regDate",
            text: "Reg. Date",
            className: "fortunate-table",
          },
        ],
        table_data: [],

        // paginated_table_data: [],
        icons: {
          pen: require("@/assets/imgs/icons/white-pen.svg"),
        },
      };
    },
    computed: {
      invoice_mode: function() {
        return this.mode === "invoice";
      },
      stock_mode: function() {
        return this.mode === "stock";
      },
      staff_mode: function() {
        return this.mode === "staff";
      },
    },
    methods: {
      ...mapMutations(["setBreadcrumbs"]),

      NavigateToSingleStock(id) {
        this.$router.push({
          name: "singleStock",
          params: { id: id },
        });
      },
      RefreshTable() {
        this.searchData.filter = null;
        this.searchData.searchQuery = null;
      },
      SearchTable() {
        this.searchData.filter = this.searchFilter;
        this.searchData.searchQuery = this.searchQuery;
      },
      FormatTableData(data) {
        this.table_data = data.map((stock) => {
          let date = new Date(Number(stock.createdAt));

          return {
            id: stock._id,
            unit: stock.unit,
            unitSellingPrice: stock.unitSellingPrice,
            createdBy: stock.authorId.name,
            itemName: stock.itemName,
            quantityRemaining: stock.quantityRemaining,
            regDate: `${date.getDate()}/${date.getMonth() +
              1}/${date.getFullYear()}`,
            btn: "btn",
          };
        });
      },
      TableButton() {
        this.$router.push({ name: "manageStock", params: { mode: "new" } });
      },
    },
    mounted() {
      this.setBreadcrumbs([{ displayName: "Stock", pathName: "stock" }]);
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .custom-select.open .custom-select-options {
    display: block;
  }
  .custom-select li.selected {
    background-color: rgb(52, 211, 153);
  }
</style>
